import React from 'react';

import { Container, Segment } from 'semantic-ui-react';

const Footer = () => {
  return (
    <Segment vertical>
      <Container textAlign='center'>
      <p><strong> © 2024 Stima Tech All Rights Reserved.</strong></p>
      </Container>
    </Segment>
  );
};

export default Footer;
