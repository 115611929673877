import React from 'react';
import { Container, Header, Message, Icon, Button } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';

const PaymentConfirmation = () => {
  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');

  return (
    <Container text style={{ marginTop: '2em' }}>
      <Header as='h2' icon textAlign='center'>
        <Icon name='check circle' color='green' />
        付款成功
      </Header>
      <Message positive>
        <Message.Header>感謝您的購買！</Message.Header>
        <p>
          我們已經將加值碼發送到您的電子郵件地址：<strong>{email}</strong>
        </p>
        <p>請檢查您的收件箱（包括垃圾郵件匣）以獲得加值碼。</p>
        <p>收到加值碼後，您可以到<a href="https://api.stima.tech/topup"> 加值區</a> 進行加值。</p>
      </Message>
      <p>如果您在 15 分鐘內沒有收到加值碼，請聯繫我們的客服人員。</p>
      <div style={{ textAlign: 'center', marginTop: '2em' }}>
        <Button
          as='a'
          href="mailto:support@stima.tech"
          color='teal'
          size='large'
          icon
          labelPosition='left'
        >
          <Icon name='mail' />
          聯繫客服
        </Button>
      </div>
    </Container>
  );
};

export default PaymentConfirmation;