import React, { useState } from 'react';
import { Container, Header, Table, Button, Grid, Segment, Modal, List, Image } from 'semantic-ui-react';

// const plans = [
//   { id: 1, image: 'one-day.png', twd: 40, label: '1日方案' },
//   { id: 2, image: '7-day.png', twd: 120, label: '7日方案' },
//   { id: 3, image: '14-day.png', twd: 180, label: '14日方案' },
// ];

const plans = [
  { id: 1, usd: 5, twd: 75, label: '方案A' },
  { id: 2, usd: 8, twd: 120, label: '方案B' },
  { id: 3, usd: 12, twd: 180, label: '方案C' },
  { id: 4, usd: 16, twd: 250, label: '方案D' },
  { id: 5, usd: 25, twd: 375, label: '方案E' },
];

const policies = [
  { 
    id: 1, 
    title: '退款政策', 
    content: [
      '根據中華民國消費者保護法及中華民國經濟部商業司的規定，我們的退款政策如下：',
      '1. 七日內無條件退款：',
      '   - 依據消費者保護法第19條，消費者得於收受商品或接受服務後七日內，以退回商品或書面通知方式解除契約，無須說明理由及負擔任何費用或對價。',
      '   - Stima API 服務屬於「非以有形媒介提供之數位內容或一經提供即為完成之線上服務」，因此不適用七日內無條件退款規定。',
      '2. 特殊情況退款：',
      '   - 如果我們的服務出現重大技術問題導致無法使用，我們將根據系統中斷服務的時長提供退款或補償。',
      '   - 未使用的 API 額度可在購買後30天內申請退款。',
      '3. 退款處理：',
      '   - 退款將在收到申請後3個工作日內處理。',
      '   - 退款將退回原付款帳戶或方式。',
      '4. 退款限制：',
      '   - 已使用的 API 額度不予退款。',
      '   - 因違反使用條款而被終止服務的帳戶不予退款。',
      '如有任何疑問，請聯繫我們的客戶服務團隊。'
    ]
  },
  { 
    id: 2, 
    title: '隱私政策', 
    content: [
      '依據中華民國個人資料保護法，我們的隱私政策如下：',
      '1. 資料收集：',
      '   - 我們僅收集必要的個人資料，包括但不限於：電子郵件地址、使用記錄。',
      '   - 我們不會蒐集您通過對話系統 StimaChat 傳輸的數據，亦不會存儲任何個人敏感資料。',
      '2. 資料使用：',
      '   - 個人資料僅用於提供 StimaChat 對話系統服務、改善用戶體驗、必要的客戶支援和帳單管理。',
      '   - 我們可能會使用匿名化的數據來優化 StimaChat 對話系統的性能。',
      '3. 資料保護：',
      '   - 我們採用業界標準的加密技術和安全措施來保護您的個人資料。',
      '   - 我們定期進行安全審計，以確保數據安全。',
      '4. 資料分享：',
      '   - 未經您的同意，我們不會向第三方披露您的個人資料，除非法律要求。',
      '   - 我們可能與我們的服務提供商分享必要的資料，但會確保他們遵守同等的隱私標準。',
      '5. 您的權利：',
      '   - 您有權查閱、更正您的個人資料，並要求刪除不再需要的資料。',
      '   - 您可以隨時要求我們停止處理您的個人資料。',
      '6. 數據保留：',
      '   - 我們只在必要的時間內保留您的個人資料，以履行本政策中所述的目的。',
      '7. 國際傳輸：',
      '   - 您的資料可能會被傳輸和存儲在中華民國境外的伺服器上。我們將確保這些傳輸符合適用的資料保護法。',
      '8. 政策更新：',
      '   - 我們可能會不時更新此隱私政策。重大變更將通過電子郵件通知您。',
      '如果您對我們的隱私政策有任何疑問，請聯繫我們的客服團隊。'
    ]
  },
  { 
    id: 3, 
    title: '使用條款', 
    content: [
      '使用我們的 StimaChat 對話系統服務即表示您同意以下條款：',
      '1. 服務使用：',
      '   - 您同意不會將我們的服務用於任何非法或未經授權的目的。',
      '   - 您負責保護您的 API 密鑰和帳戶憑證的安全。',
      '   - 您同意不會超出您購買的 API 使用限額。超出限額可能會導致服務中斷或額外費用。',
      '2. 內容責任：',
      '   - 您理解並同意，我們的 AI 模型可能並非總是 100% 準確，您應自行驗證重要輸出。',
      '   - 您同意不會使用我們的服務來創建或傳播有害、欺詐、色情、暴力或侵犯他人權利的內容。',
      '   - 您對通過我們的 API 創建或處理的所有內容負全責。',
      '3. 知識產權：',
      '   - 我們的服務和相關技術受知識產權法保護。',
      '   - 您保留您通過我們的 API 處理的內容的所有權利。',
      '   - 您授予我們使用您的內容的有限許可，以提供和改進我們的服務。',
      '4. 服務變更和終止：',
      '   - 我們保留隨時修改、暫停或終止服務的權利。',
      '   - 我們保留在發現違規使用時暫停或終止您的帳戶的權利。',
      '5. 責任限制：',
      '   - 在法律允許的最大範圍內，我們對使用我們服務造成的任何直接或間接損失不承擔責任。',
      '6. 爭議解決：',
      '   - 任何爭議將依中華民國法律解決，並受台灣高雄地方法院管轄。',
      '7. 條款更新：',
      '   - 我們保留隨時修改這些條款的權利，重大變更將通過電子郵件通知您。',
      '8. 遵守法律：',
      '   - 您同意遵守所有適用的中華民國法律法規。',
      '',
      '違反這些條款可能導致帳戶暫停或終止，嚴重違規可能承擔法律責任。',
      '如果您對這些條款有任何疑問，請聯繫我們的客服團隊。'
    ]
  },
];

const PaymentPage = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleOpenModal = (policy) => {
    setModalContent(policy);
    setOpenModal(true);
  };

  // 新增前往蝦皮賣場的函數
  const goToShopee = () => {
    window.open('https://shopee.tw/product/32191180/28711681086/', '_blank');
  };

  return (
    <Container style={{ padding: '2em 0' }}>
      <Grid>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Header as='h1' textAlign='center'>選擇購買方案 『維護中，請勿下單購買』</Header>
        </Grid.Column>
      </Grid>

      {/* 大屏幕顯示 */}
      <Grid className='large screen only'>
        <Grid.Column width={16}>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>方案</Table.HeaderCell>
                <Table.HeaderCell>API額度</Table.HeaderCell>
                <Table.HeaderCell>新台幣 (TWD) (不含稅)</Table.HeaderCell>
                <Table.HeaderCell>選擇</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {plans.map((plan) => (
                <Table.Row key={plan.id}>
                  <Table.Cell>{plan.label}</Table.Cell>
                  <Table.Cell>${plan.usd}</Table.Cell>
                  <Table.Cell>NT${plan.twd}</Table.Cell>
                  <Table.Cell>
                    <Button 
                      primary 
                      onClick={() => handleSelectPlan(plan)}
                    >
                      選擇
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>

      {/* 小屏幕顯示 */}
      {/* 小屏幕顯示 */}
      <Grid className='mobile only'>
        {plans.map((plan) => (
          <Grid.Column width={16} key={plan.id} style={{ marginBottom: '1em' }}>
            <Segment>
              <Header as='h3'>{plan.label}</Header>
              <p>API額度: ${plan.usd}</p>
              <p>新台幣: NT${plan.twd} (不含稅)</p>
              <Button 
                fluid
                primary 
                onClick={() => handleSelectPlan(plan)}
              >
                選擇
              </Button>
            </Segment>
          </Grid.Column>
        ))}
      </Grid>

      {/* 政策內容 */}
      <Grid>
        <Grid.Column width={16}>
          <Header as='h3' textAlign='center'>重要政策</Header>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '1em', marginTop: '1em' }}>
            {policies.map((policy) => (
              <Button key={policy.id} onClick={() => handleOpenModal(policy)}>
                {policy.title}
              </Button>
            ))}
          </div>
        </Grid.Column>
      </Grid>

      {selectedPlan && (
        <Container textAlign='center' style={{ marginTop: '2em' }}>
          <Header as='h3'>您已選擇 {selectedPlan.label}</Header>
          <p>價格：NT${selectedPlan.twd} TWD</p>
          <Button primary onClick={goToShopee}>前往蝦皮賣場</Button>
        </Container>
      )}

      {/* 彈出窗口 */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        size='small'
      >
        <Modal.Header>{modalContent.title}</Modal.Header>
        <Modal.Content scrolling>
          <List>
            {Array.isArray(modalContent.content) 
              ? modalContent.content.map((line, index) => (
                  <List.Item key={index}>
                    {line === '' ? <br /> : line}
                  </List.Item>
                ))
              : <p>{modalContent.content}</p>
            }
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>關閉</Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default PaymentPage;